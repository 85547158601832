import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterModule, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { LoggedInUser } from '../login/loggedInUser';
import { AppSettings } from '../shared/app.settings';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedRouteGuard implements CanActivate, CanDeactivate<unknown> {

  constructor(private loggedInUser: LoggedInUser, private router: Router, private appSettings: AppSettings) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var user = this.getLoggedInUser();
    if (user === null || user.hasToken === false) {
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'You are not authorize to view this content.',
      })
      this.router.navigateByUrl('');
    }

    return true;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  public setCanActivate(user: LoggedInUser) {
    this.loggedInUser = user;

    //set and get local storage data
    sessionStorage.setItem(this.appSettings.sessionStorageKey, JSON.stringify(this.loggedInUser));
  }

  public getLoggedInUser(): LoggedInUser {
    if (!this.loggedInUser) {
      return this.loggedInUser;
    }

    var json = sessionStorage.getItem(this.appSettings.sessionStorageKey);
    var userFromStorage: LoggedInUser = JSON.parse(json!);
    return userFromStorage;
  }

  setDeactivate() {
    this.loggedInUser = null!;
    sessionStorage.removeItem(this.appSettings.sessionStorageKey);
  }

  public isLoggedIn(): boolean {
    let status = false;

    if (sessionStorage.getItem(this.appSettings.sessionStorageKey) != null) {
      status = true;
    }
    else {
      status = false;
    }

    return status;
  }
}
