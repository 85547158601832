import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoggedInUser } from 'src/app/login/loggedInUser';
import { LoginUser } from 'src/app/login/login-user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private signInBehaviorSubject = new BehaviorSubject<LoggedInUser>(undefined!);
  onSignInMessage = this.signInBehaviorSubject.asObservable();
  
  private newUserCreatedBehaviorSubject = new BehaviorSubject<LoginUser>(undefined!);
  onNewUserCreatedMessage = this.newUserCreatedBehaviorSubject.asObservable();

  private authenticationChangeBehaviorSubject = new BehaviorSubject<LoginUser>(undefined!);
  onAuthenticationChangeMessage = this.authenticationChangeBehaviorSubject.asObservable();

  private verifyEmailBehaviorSubject = new BehaviorSubject<string>(undefined!);
  onVerifyEmailChangeMessage = this.verifyEmailBehaviorSubject.asObservable();

   // tslint:disable-next-line:max-line-length
   constructor(private router: Router) {
    //this.itemsCollection = angularFirestore.collection<KhazuUser>('users');
    //this.items = this.itemsCollection.valueChanges();
    //this.angularFireAuth.auth.onAuthStateChanged(firebaseUser => { this.firebaseAuthChangeEvent(firebaseUser); });
  }

  public signInWithEmailAndPassword(username: string, password: string) {
    const loginUser = new LoginUser();

    /*this.angularFireAuth.auth.signInWithEmailAndPassword(username, password)
      .catch(error => {
        this.logger.LogIt(error);
        loginUser.error = error;
        loginUser.hasErrors = true;
        this.onSigInCompleted(loginUser);
      })
      .then(results => {

        if (results === undefined) { return; }
        loginUser.firebaseUser = results;

        if (loginUser.firebaseUser.emailVerified === false) {
          this.sendVerifyEmailVerificationEmail();
          return;
        }

        this.onSigInCompleted(loginUser);
      });*/
  }

  public logout() {
    /*this.angularFireAuth.auth.signOut()
      .catch(error => {
        this.logger.LogIt(error);
      })
      .then(results => {
        // this.loginUser = null;
      });*/
  }

  public sendVerificationEmail() {
    /*this.angularFireAuth.auth.currentUser.sendEmailVerification()
      .catch((error) => {
        this.logger.LogIt(error);
        this.onVerifyEmailCompleted('Unable to resend email verification');
      })
      .then(completed => {
        this.logger.LogIt(completed);
        this.onVerifyEmailCompleted('Email verification sent successfully!');
        this.router.navigate(['/email-verification']);
      });*/
  }

  private onSigInCompleted(loginUser: LoginUser) {
    var loggedInUser = new LoggedInUser();
    this.signInBehaviorSubject.next(loggedInUser);
  }

  private onNewUserCreatedCompleted(loginUser: LoginUser) {
    this.newUserCreatedBehaviorSubject.next(loginUser);
  }

  private onVerifyEmailCompleted(message: string) {
    this.verifyEmailBehaviorSubject.next(message);
  }

  isLoggedIn(): boolean {
    return false;
  }
}
