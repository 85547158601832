<div class="content__boxed d-md-flex">

    <!-- Dashboard content -->
    <div class="content__wrap order-2 flex-fill min-w-0">
        <div class="row mb-3 mb-md-4">
            <div class="col-xl-5">

                <!-- Activated Users -->
                <h3>Activated Users</h3>
                <p class="text-muted">Report for last 7-days ago</p>

                <div class="d-md-flex my-3">
                    <div class="flex-fill mb-3">
                        <div class="display-1 h5">520</div>
                        <p class="mb-3">Since 2019 790 peoples already registered</p>
                        <button type="button" class="btn btn-info btn-sm">View details</button>
                    </div>
                    <div class="w-md-250px flex-shrink-0 mb-3">
                        <div class="list-group list-group-borderless">
                            <a class="list-group-item list-group-item-action" href="#"><i class="demo-pli-information fs-5 me-2"></i> User Details</a>
                            <a class="list-group-item list-group-item-action" href="#"><i class="demo-pli-mine fs-5 me-2"></i> Usage Profile</a>
                            <a class="list-group-item list-group-item-action" href="#"><i class="demo-pli-credit-card-2 fs-5 me-2"></i> Payment Options <span class="badge bg-danger ms-3">New</span></a>
                            <a class="list-group-item list-group-item-action" href="#"><i class="demo-pli-mail fs-5 me-2"></i> Messages</a>
                            <a class="list-group-item list-group-item-action" href="#"><i class="demo-pli-gear fs-5 me-2"></i> Settings</a>
                        </div>
                    </div>
                </div>
                <!-- END : Activated Users -->

            </div>
            <div class="col-xl-7">

                <!-- Area Chart -->
                <app-dashboard-chart></app-dashboard-chart>
                <!-- END : Area Chart -->

            </div>
        </div>
        <div class="row">
            <div class="col-xl-3">
                <div class="row g-sm-1 mb-3">
                    <div class="col-sm-6">

                        <!--Tile-->
                        <div class="card bg-primary text-white mb-1 mb-xl-1">
                            <div class="p-3 text-center">
                                <span class="display-5">53</span>
                                <p>Sales</p>
                                <i class="demo-psi-shopping-bag text-white text-opacity-50 fs-5"></i>
                            </div>
                        </div>
                        <!--END : Tile-->

                        <!--Tile-->
                        <div class="card bg-warning text-white mb-1 mb-xl-1">
                            <div class="p-3 text-center">
                                <span class="display-5">68</span>
                                <p>Messages</p>
                                <i class="demo-psi-mail text-white text-opacity-50 fs-5"></i>
                            </div>
                        </div>
                        <!--END : Tile-->

                    </div>
                    <div class="col-sm-6">

                        <!--Tile-->
                        <div class="card bg-info text-white mb-1 mb-xl-1">
                            <div class="p-3 text-center">
                                <span class="display-5">32</span>
                                <p>Projects</p>
                                <i class="demo-psi-coding text-white text-opacity-50 fs-5"></i>
                            </div>
                        </div>
                        <!--END : Tile-->

                        <!--Tile-->
                        <div class="card bg-success text-white mb-1 mb-xl-1">
                            <div class="p-3 text-center">
                                <span class="display-5">12</span>
                                <p>Reports</p>
                                <i class="demo-psi-receipt-4 text-white text-opacity-50 fs-5"></i>
                            </div>
                        </div>
                        <!--END : Tile-->

                    </div>
                </div>
            </div>
            <div class="col-xl-9">

                <!-- Users Table -->
                <div class="table-responsive mw-100">
                    <table class="table table-borderless table-sm align-middle">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th width="40">User</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th class="text-center">Subscription</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="min-w-td">1</td>
                                <td><img class="img-xs rounded-circle" src="./assets/img/profile-photos/1.png" alt="Profile Picture"></td>
                                <td><a class="btn-link" href="#">John Doe</a></td>
                                <td>john.doe@example.com</td>
                                <td class="fs-5"><span class="d-block badge bg-info">Trial</span></td>
                                <td class="text-center text-nowrap">
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-pen-5 fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-danger btn-hover" href="#"><i class="demo-pli-trash fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-unlock fs-5"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td class="min-w-td">2</td>
                                <td><img class="img-xs rounded-circle" src="./assets/img/profile-photos/2.png" alt="Profile Picture"></td>
                                <td><a class="btn-link" href="#">Charles S Boyle</a></td>
                                <td>char_boy90@example.com</td>
                                <td class="fs-5"><span class="d-block badge bg-success">Free</span></td>
                                <td class="text-center text-nowrap">
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-pen-5 fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-danger btn-hover" href="#"><i class="demo-pli-trash fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-unlock fs-5"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td class="min-w-td">3</td>
                                <td><img class="img-xs rounded-circle" src="./assets/img/profile-photos/3.png" alt="Profile Picture"></td>
                                <td><a class="btn-link" href="#">Scott S. Calabrese</a></td>
                                <td>scot.em23@example.com</td>
                                <td class="fs-5"><span class="d-block badge bg-purple">Bussiness</span></td>
                                <td class="text-center text-nowrap">
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-pen-5 fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-danger btn-hover" href="#"><i class="demo-pli-trash fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-unlock fs-5"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td class="min-w-td">4</td>
                                <td><img class="img-xs rounded-circle" src="./assets/img/profile-photos/4.png" alt="Profile Picture"></td>
                                <td><a class="btn-link" href="#">Lucy Moon</a></td>
                                <td>just_lucy.doe@example.com</td>
                                <td class="fs-5"><span class="d-block badge bg-secondary">Personal</span></td>
                                <td class="text-center text-nowrap">
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-pen-5 fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-danger btn-hover" href="#"><i class="demo-pli-trash fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-unlock fs-5"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td class="min-w-td">5</td>
                                <td><img class="img-xs rounded-circle" src="./assets/img/profile-photos/6.png" alt="Profile Picture"></td>
                                <td><a class="btn-link" href="#">Maria Marz</a></td>
                                <td>maria_545@example.com</td>
                                <td class="fs-5"><span class="d-block badge bg-info">Trial</span></td>
                                <td class="text-center text-nowrap">
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-pen-5 fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-danger btn-hover" href="#"><i class="demo-pli-trash fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-unlock fs-5"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td class="min-w-td">6</td>
                                <td><img class="img-xs rounded-circle" src="./assets/img/profile-photos/5.png" alt="Profile Picture"></td>
                                <td><a class="btn-link" href="#">Charles S Boyle</a></td>
                                <td>char_boy90@example.com</td>
                                <td class="fs-5"><span class="d-block badge bg-success">Free</span></td>
                                <td class="text-center text-nowrap">
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-pen-5 fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-danger btn-hover" href="#"><i class="demo-pli-trash fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-unlock fs-5"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td class="min-w-td">7</td>
                                <td><img class="img-xs rounded-circle" src="./assets/img/profile-photos/9.png" alt="Profile Picture"></td>
                                <td><a class="btn-link" href="#">Lucy Moon</a></td>
                                <td>just_lucy.doe@example.com</td>
                                <td class="fs-5"><span class="d-block badge bg-secondary">Personal</span></td>
                                <td class="text-center text-nowrap">
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-pen-5 fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-danger btn-hover" href="#"><i class="demo-pli-trash fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-unlock fs-5"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td class="min-w-td">8</td>
                                <td><img class="img-xs rounded-circle" src="./assets/img/profile-photos/10.png" alt="Profile Picture"></td>
                                <td><a class="btn-link" href="#">Maria Marz</a></td>
                                <td>maria_545@example.com</td>
                                <td class="fs-5"><span class="d-block badge bg-info">Trial</span></td>
                                <td class="text-center text-nowrap">
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-pen-5 fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-danger btn-hover" href="#"><i class="demo-pli-trash fs-5"></i></a>
                                    <a class="btn btn-icon btn-sm btn-primary btn-hover" href="#"><i class="demo-pli-unlock fs-5"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!--Pagination-->
                    <nav class="text-align-center mt-5" aria-label="Table navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item disabled">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li class="page-item active" aria-current="page">
                                <span class="page-link">1</span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item disabled"><a class="page-link" href="#">...</a></li>
                            <li class="page-item"><a class="page-link" href="#">5</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <!-- END : Users Table -->

            </div>
        </div>
    </div>
    <!-- END : Dashboard content -->

    <!-- Content sidebar -->
    <aside class="w-md-250px content__wrap flex-shrink-0 pe-md-0 order-1">

        <!-- Earning and promotion info -->
        <h5 class="mb-0">Total Earning</h5>
        <p>August 17, 2028</p>
        <p class="h1">$935.09</p>

        <div class="d-grid">
            <button class="btn btn-success" type="button">Request payout</button>
        </div>

        <h5 class="mt-5 mb-3">Available Items</h5>
        <p>Get <span class="badge bg-danger">$15.00 off</span> your next bill by making sure your full payment reaches us before August 5th.</p>
        <!-- End - Earning and promotion info -->

        <!-- Additional actions nav -->
        <h5 class="mt-5">Additional Actions</h5>
        <div class="row list-group list-group-borderless gx-5 px-2">
            <a href="#" class="list-group-item list-group-item-action">
                <i class="demo-pli-information me-2 fs-5"></i>
                Services Information
            </a>
            <a href="#" class="list-group-item list-group-item-action">
                <i class="demo-pli-mine me-2 fs-5"></i>
                Usage
            </a>
            <a href="#" class="list-group-item list-group-item-action">
                <i class="demo-pli-credit-card-2 me-2 fs-5"></i>
                Payment Options
            </a>
            <a href="#" class="list-group-item list-group-item-action">
                <i class="demo-pli-support me-2 fs-5"></i>
                Messages Center
            </a>
        </div>
        <!-- End - Additional actions nav -->

        <!-- Public Settings -->
        <h5 class="mt-5">Public Settings</h5>
        <div class="list-group list-group-borderless">
            <div class="list-group-item d-flex justify-content-between mb-1 px-0">
                <label class="form-check-label" for="_dm-asdOnlineStatus">Online Status</label>
                <div class="form-check form-switch">
                    <input id="_dm-asdOnlineStatus" class="form-check-input" type="checkbox" checked>
                </div>
            </div>

            <div class="list-group-item d-flex justify-content-between mb-1 px-0">
                <label class="form-check-label" for="_dm-asdMuteNotifications">Mute Notifications</label>
                <div class="form-check form-switch">
                    <input id="_dm-asdMuteNotifications" class="form-check-input" type="checkbox" checked>
                </div>
            </div>

            <div class="list-group-item d-flex justify-content-between mb-1 px-0">
                <label class="form-check-label" for="_dm-asdMyDevicesName">Show my device name</label>
                <div class="form-check form-switch">
                    <input id="_dm-asdMyDevicesName" class="form-check-input" type="checkbox" checked>
                </div>
            </div>
        </div>
        <!-- End - Public Settings -->

    </aside>
    <!-- Content sidebar -->

</div>