import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import Swal from 'sweetalert2';
import { LoggedInUser } from '../login/loggedInUser';
import { AuthenticatedRouteGuard } from '../route-guards/authenticated-route.guard';
import { AppSettings } from '../shared/app.settings';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  loggedInUser!: LoggedInUser;

  constructor(private router: Router, private authenticatedRouteGuard: AuthenticatedRouteGuard,
    private appSettings: AppSettings) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.loadScripts();
    this.loggedInUser = this.authenticatedRouteGuard.getLoggedInUser();
  }

  private loadScripts() {
    this.createScript('../../assets/vendors/popperjs/popper.min.js');
    this.createScript('../../assets/vendors/bootstrap/bootstrap.min.js');
    this.createScript('../../assets/js/nifty.js');
    this.createScript('../../assets/vendors/zangdar/zangdar.min.js');
    this.createScript('../../assets/pages/form-wizard.js');
    this.createScript('../../assets/pages/gridjs.js');
  }

  private createScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public signOut(): void {
    sessionStorage.removeItem(this.appSettings.sessionStorageKey);

    Swal.fire({
      title: 'See ya!',
      text: "You've successfully signed out.",
      confirmButtonColor: '#1bb355',
      icon: 'success',
    }).then(res => {
      if (Swal.isVisible())
        Swal.close();
        window.location.href = '';
      window
    });
  }
}
