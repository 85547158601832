import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthenticatedRouteGuard } from '../route-guards/authenticated-route.guard';
import { IdentityService } from '../services/identity/identity.service';
import { Chef } from '../shared/chef';
import { LoggedInUser } from './loggedInUser';
import { LoginUser } from './login-user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input()
  loginUser: LoginUser = new LoginUser();

  constructor(private router: Router, private identityService: IdentityService,
    private authenticatedRouteGuard: AuthenticatedRouteGuard) { }

  ngOnInit(): void { }

  signIn(): void {

    Swal.fire({
      title: `Welcome!`,
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      text: 'Checking credentials..',
      didOpen: () => {
        Swal.showLoading();
        this.login();
      }
    });
  }

  private showErrorDialog(message?: string) {
    var defaultError = "Something went wrong.";
    Swal.fire({
      title: 'Oops',
      text: message == null ? defaultError : message,
      icon: 'error',
    }).then(res => {
      if (Swal.isVisible())
        Swal.close();
    });
  }

  private login(): void {
    var loggedInUser = new LoggedInUser();
    //Swal.close();
    //this.router.navigate(['portal', { outlets: { aside: 'dashboard' }, }]);
    //return;

    this.identityService.signIn<Chef>(this.loginUser).subscribe((res: Chef) => {
      Swal.close();

      if (res.error) {
        this.showErrorDialog(res.error.errorMessage);
        return;
      }

      var loggedInUser = new LoggedInUser();
      loggedInUser.isAuthenticated = true;
      loggedInUser.hasToken = true;
      this.authenticatedRouteGuard.setCanActivate(loggedInUser);
      this.router.navigate(['portal', { outlets: { aside: 'dashboard' }, }]);
    }, (error: any) => {
      this.showErrorDialog();
    });
  }
}
