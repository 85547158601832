import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LoggedInUser } from './login/loggedInUser';
import { AuthenticatedRouteGuard } from './route-guards/authenticated-route.guard';
import { AppSettings } from './shared/app.settings';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [AuthenticatedRouteGuard, LoggedInUser, AppSettings],
  bootstrap: [AppComponent]
})
export class AppModule { }
