import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthenticatedRouteGuard } from './route-guards/authenticated-route.guard';
import { AppSettings } from './shared/app.settings';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'phylio-portal';
  userActivity: any;
  userInactive: Subject<any> = new Subject();

  constructor(private appSettings: AppSettings, private router: Router,
    private authenticatedRouteGuard: AuthenticatedRouteGuard) {
      console.log(`Running on Backend: ${environment.backendServer}`);
  }

  ngOnInit(): void {
    this.setTimeout();
    this.userInactive.subscribe(() => { this.sessionTimeout(); });
  }

  keyPress(event: KeyboardEvent): void {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  private setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.appSettings.sessionTimeout);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  private sessionTimeout() {
    sessionStorage.removeItem(this.appSettings.sessionStorageKey);
    window.location.href = '';
  }
}
