import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { DocsComponent } from './docs/docs.component';
import { DocsModule } from './docs/docs.module';
import { LoginComponent } from './login/login.component';
import { LoginModule } from './login/login.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { ProfileComponent } from './profile/profile.component';
import { ProfileModule } from './profile/profile.module';
import { AuthenticatedRouteGuard } from './route-guards/authenticated-route.guard';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleModule } from './schedule/schedule.module';
import { AuthService } from './services/auth/auth.service';
import { HttpService } from './services/http/http-service';
import { ShellComponent } from './shell/shell.component';
import { ShellModule } from './shell/shell.module';
import { IdentityService } from './services/identity/identity.service';
import { EmailComponent } from './email/email.component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationModule } from './registration/registration.module';
import { SessionRouteGuard } from './route-guards/session-route.guard';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [SessionRouteGuard]},
  { path: 'registration', component: RegistrationComponent },
  { path: 'portal', component: ShellComponent,
    canActivate: [AuthenticatedRouteGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent, outlet:'aside',  
                canActivate: [AuthenticatedRouteGuard] },
      { path: 'profile', component: ProfileComponent, outlet:'aside',  
                canActivate: [AuthenticatedRouteGuard] },
      { path: 'schedule', component: ScheduleComponent, outlet:'aside',  
      canActivate: [AuthenticatedRouteGuard] },
      { path: 'docs', component: DocsComponent, outlet:'aside',  
      canActivate: [AuthenticatedRouteGuard] },
      { path: 'email', component: EmailComponent, outlet:'aside',  
      canActivate: [AuthenticatedRouteGuard] },
      /*{ path: 'payments', component: PaymentsComponent, outlet: 'aside',  
                canActivate: [AuthenticatedRouteGuard] }*/
    ]
   },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    LoginModule,
    DashboardModule,
    ShellModule,
    DocsModule,
    ProfileModule,
    ScheduleModule,
    PageNotFoundModule,
    RegistrationModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: [AuthService, HttpService, IdentityService]
})
export class AppRoutingModule { }
