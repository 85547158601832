import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  endpoint!: string;

  constructor(private http: HttpClient) { }

  // Http Headers
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  // POST
  post<T>(data: any): Observable<T> {
    return this.http.post<T>(this.endpoint, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandle)
      )
  };

  // GET
  get<T>(params?: string): Observable<T> {
    var url = params == null ? this.endpoint 
                             : this.endpoint + params;
                             
    return this.http.get<T>(url)
      .pipe(
        retry(1),
        catchError(this.errorHandle))
  };

  // PUT
  put<T>(id: string, data: any): Observable<T> {
    return this.http.put<T>(this.endpoint, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandle)
      )
  };

  // DELETE
  delete<T>(id: string) {
    return this.http.delete<T>(this.endpoint, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandle)
      )
  };

  // Error handling
  private errorHandle(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
