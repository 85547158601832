<!-- PAGE CONTAINER -->
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<div id="root" class="root front-container">

    <!-- CONTENTS -->
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <section id="content" class="content">
        <div
            class="content__boxed card rounded-0 w-100 min-vh-100 d-flex flex-column align-items-stretch justify-content-center">
            <div class="content__wrap">

                <div class="text-center">
                    <div class="error-code page-title mb-3">404</div>
                    <h3 class="mb-4">
                        <div class="badge bg-info">Page not found !</div>
                    </h3>
                    <p class="lead">Sorry, but the page you are looking for has not been found on our server.</p>
                </div>

                <!-- Action buttons -->
                <div class="d-flex justify-content-center gap-3 mt-4">
                    <button type="button" onclick="window.history.back()" class="btn btn-light">Go back</button>
                </div>
                <!-- END : Action buttons -->
            </div>
        </div>
    </section>

    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <!-- END - CONTENTS -->
    <!-- FOOTER -->

    <footer class="footer content__wrap border-top">
        <div class="row mt-4">
            <div class="col-12 col-sm-6 col-lg-4 mb-4">

                <p><strong class="h5">Nifty</strong> is is flat admin template for multi-purpose usage built with the
                    latest version of Bootstrap.</p>
                <p class="mb-0">Made with<i class="demo-psi-heart-2 fs-4 text-danger mx-1"></i>by <a
                        class="ml-1 fw-semibold btn-link" href="https://themeon.net/">ThemeOn</a></p>

                <div class="mt-4 pt-3 border-top text-muted">
                    <a href="#" class="btn btn-sm btn-icon btn-hover btn-primary text-inherit">
                        <i class="demo-psi-facebook fs-5"></i>
                    </a>
                    <a href="#" class="btn btn-sm btn-icon btn-hover btn-info text-inherit">
                        <i class="demo-psi-twitter fs-5"></i>
                    </a>
                    <a href="#" class="btn btn-sm btn-icon btn-hover btn-danger text-inherit">
                        <i class="demo-psi-google-plus fs-5"></i>
                    </a>
                    <a href="#" class="btn btn-sm btn-icon btn-hover btn-warning text-inherit">
                        <i class="demo-psi-instagram fs-5"></i>
                    </a>
                </div>

            </div>
            <div class="col-12 col-sm-6 col-lg mb-4">

                <h5>About</h5>
                <nav class="nav flex-column">
                    <a class="nav-link px-0" href="#">About Us</a>
                    <a class="nav-link px-0" href="#">Corporate</a>
                    <a class="nav-link px-0" href="#">Terms &amp; Policy</a>
                    <a class="nav-link px-0" href="#">Community</a>
                </nav>

            </div>
            <div class="col-12 col-sm-6 col-lg mb-4">

                <h5>Support</h5>
                <nav class="nav flex-column">
                    <a class="nav-link px-0" href="#">Help</a>
                    <a class="nav-link px-0" href="#">Support</a>
                    <a class="nav-link px-0" href="#">Privacy Policy</a>
                    <a class="nav-link px-0" href="#">Help &amp; Support</a>
                </nav>

            </div>
            <div class="col-12 col-sm-6 col-lg mb-4">

                <h5>Contact</h5>
                <nav class="nav flex-column">
                    <a class="nav-link px-0" href="#">Call Centre</a>
                    <a class="nav-link px-0" href="#">Email Us</a>
                    <a class="nav-link px-0" href="#">Term &amp; Conditions</a>
                    <a class="nav-link px-0" href="#">Help Center</a>
                </nav>

            </div>
        </div>
    </footer>

    <!-- END - FOOTER -->
</div>
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<!-- END - PAGE CONTAINER -->
<!-- SCROLL TO TOP BUTTON -->
<div class="scroll-container">
    <a href="#root" class="scroll-page rounded-circle ratio ratio-1x1" aria-label="Scroll button"></a>
</div>
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<!-- END - SCROLL TO TOP BUTTON -->