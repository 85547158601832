import { Component, ViewChild } from '@angular/core';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js';

function registerChartComponents() {
  Chart.register(CategoryScale);
  Chart.register(LinearScale);
  Chart.register(BarController);
  Chart.register(BarElement);
  Chart.register(LineController);
  Chart.register(LineElement);
  Chart.register(PointElement);
}

registerChartComponents();

@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss']
})
export class DashboardChartComponent {
  _body: CSSStyleDeclaration | undefined;
  infoColor: string | undefined;
  warningColor: string | undefined;
  grayColor: string | undefined;
  textColor: string | undefined;

  canvas: any;
  ctx: any;
  
  @ViewChild('mychart')
  pathwayChart!: { nativeElement: any };
  
  chart!: Chart;
  stackedData!: ChartData;
  stackedOptions!: ChartOptions;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  private createChart() {
    this._body = getComputedStyle(document.body);
    this.infoColor = this._body.getPropertyValue('--bs-info');
    this.warningColor = this._body.getPropertyValue('--bs-warning');
    this.grayColor = this._body.getPropertyValue('--bs-gray-700');
    this.textColor = this._body.getPropertyValue('--bs-body-color')
    
    this.canvas = this.pathwayChart.nativeElement;
    this.ctx = this.canvas.getContext('2d');

    this.stackedData = {
      labels: ["May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov"],
      datasets: [{
        type: 'bar',
        data: [86, 114, 106, 106, 107, 111, 133],
        label: "Students",
        borderColor: this.infoColor,
        backgroundColor: this.infoColor,
        borderWidth: 2,
        parsing: {
          xAxisKey: 'y',
          yAxisKey: 'a'
        }
      }, {
        type: 'bar',
        data: [70, 490, 434, 60, 83, 290, 100],
        label: "Parents",
        borderColor: this.warningColor,
        backgroundColor: this.warningColor,
        borderWidth: 2,
        parsing: {
          xAxisKey: 'y',
          yAxisKey: 'b'
        }
      }, {
        type: 'bar',
        data: [510, 21, 340, 44, 17, 21, 17],
        label: "Teachers",
        borderColor: this.grayColor,
        backgroundColor: this.grayColor,
        borderWidth: 2,
        parsing: {
          xAxisKey: 'y',
          yAxisKey: 'c'
        }
      }]
    };

    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: this.stackedData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            labels: {
              boxWidth: 10,
            }
          },
          tooltip: {
            position: 'nearest'
          }
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          y: {
            grid: {
              color: "rgba(0,0,0, .03)"
            },
            suggestedMax: 650,
            ticks: {
              font: { size: 11 },
              color: this.textColor,
              stepSize: 150
            }
          },
          x: {
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              font: { size: 11 },
              color: this.textColor,
              autoSkip: true,
              maxRotation: 0,
              minRotation: 0,
              maxTicksLimit: 7
            }
          }
        },
        elements: {
          // Dot width
          point : {
              radius: 3,
              hoverRadius: 5
          },
          // Smooth lines
          line: {
              tension: 0.2
          }
      },
    }});
  }
}
