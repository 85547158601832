<!-- <div  style='background-image: url("../../assets/premium/boxed-bg/abstract/bg/14.jpg");' class="content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
    <div class="content__wrap" style="padding-top: 20vh;">
        <div class="row">
            <div class="col col-lg-7 hidden-sm-down" style="width: 43rem;">
                <h2 class="fs-xxl fw-500 mt-4 text-white">
                    The simplest UI toolkit for developers &amp; programmers
                    <small class="h5 fw-300 mt-3 mb-5 text-white opacity-60">
                        Presenting you with the next level of innovative UX design and engineering. The most modular toolkit available with over 600+ layout permutations. Experience the simplicity of SmartAdmin, everywhere you go!
                    </small>
                </h2>
                <a href="#" class="fs-lg fw-500 text-white opacity-70">Learn more &gt;&gt;</a>
                <div class="d-sm-flex flex-column align-items-center justify-content-center d-md-block">
                    <div class="px-0 py-1 mt-5 text-white fs-nano opacity-50">
                        Find us on social media
                    </div>
                    <div class="d-flex flex-row opacity-70">
                        <a href="#" class="mr-2 fs-xxl text-white">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                        <a href="#" class="mr-2 fs-xxl text-white">
                            <i class="fab fa-twitter-square"></i>
                        </a>
                        <a href="#" class="mr-2 fs-xxl text-white">
                            <i class="fab fa-google-plus-square"></i>
                        </a>
                        <a href="#" class="mr-2 fs-xxl text-white">
                            <i class="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col col-lg-7 hidden-sm-down" style="width: 13rem;">
            </div>
            <div class="col col-lg-6 card shadow-lg" style="width: 23rem;">
                <div class="card-body">

                    <div class="text-center">
                        <h1 class="h1">Phylio</h1>
                        <br/>
                        <p>Sign In to your account</p>
                    </div>

                    <form class="mt-4">
                        <div class="mb-3">
                            <input [(ngModel)]="loginUser.username" name="loginUser.username" type="email" 
                                    class="form-control form-control-lg" 
                                    name="email" id="signinSrEmail" tabindex="1" 
                                    placeholder="email@address.com"
                                    aria-label="email@address.com" required>
                        </div>

                        <div class="mb-3">
                            <input [(ngModel)]="loginUser.password" name="loginUser.password" type="password" class="js-toggle-password form-control form-control-lg" name="password" id="signupSrPassword" placeholder="8+ characters required" aria-label="8+ characters required" required
                            minlength="8" data-hs-toggle-password-options='{
                            "target": "#changePassTarget",
                            "defaultClass": "bi-eye-slash",
                            "showClass": "bi-eye",
                            "classChangeTarget": "#changePassIcon"
                        }'>
                        </div>

                        <div class="form-check">
                            <input id="_dm-loginCheck" class="form-check-input" type="checkbox">
                            <label for="_dm-loginCheck" class="form-check-label">
                                Remember me
                            </label>
                        </div>

                        <div class="d-grid mt-5">
                            <button (click)="signIn()" type="submit" class="btn btn-primary btn-lg">Sign in</button>
                        </div>
                    </form>

                    <div class="d-flex justify-content-between mt-4">
                        <a href="#" class="btn-link text-decoration-none">Forgot password ?</a>
                    </div>
                
                </div>
            </div>            
        </div>  
    </div>
    <footer class="mt-auto">
        <div class="content__boxed">
            <div class="content__wrap py-3 py-md-1 d-flex flex-column flex-md-row align-items-md-center">
                <div class="text-nowrap mb-4 mb-md-0">Copyright &copy; 2023 <a href="#" class="ms-1 btn-link fw-bold">Phylio, LLC</a></div>
            </div>
        </div>
    </footer>
</div> -->


<div style='background-image: url("../../assets/premium/boxed-bg/abstract/bg/14.jpg");' class="content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
    <div class="content__wrap" style="padding-top: 1vh;">
        <div class="row">
            <!--<div class="col col-lg-7 hidden-sm-down" style="width: 43rem;">
                <h2 class="fs-xxl fw-500 mt-4 text-white">
                    The simplest UI toolkit for developers &amp; programmers
                    <small class="h5 fw-300 mt-3 mb-5 text-white opacity-60">
                        Presenting you with the next level of innovative UX design and engineering. The most modular toolkit available with over 600+ layout permutations. Experience the simplicity of SmartAdmin, everywhere you go!
                    </small>
                </h2>
                <a href="#" class="fs-lg fw-500 text-white opacity-70">Learn more &gt;&gt;</a>
                <div class="d-sm-flex flex-column align-items-center justify-content-center d-md-block">
                    <div class="px-0 py-1 mt-5 text-white fs-nano opacity-50">
                        Find us on social media
                    </div>
                    <div class="d-flex flex-row opacity-70">
                        <a href="#" class="mr-2 fs-xxl text-white">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                        <a href="#" class="mr-2 fs-xxl text-white">
                            <i class="fab fa-twitter-square"></i>
                        </a>
                        <a href="#" class="mr-2 fs-xxl text-white">
                            <i class="fab fa-google-plus-square"></i>
                        </a>
                        <a href="#" class="mr-2 fs-xxl text-white">
                            <i class="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col col-lg-7 hidden-sm-down" style="width: 13rem;">
            </div>-->
            <div class="col col-lg-6 card shadow-lg" style="width: 23rem;">
                <div class="card-body">

                    <div class="text-center">
                        <h1 class="h1">Phylio</h1>
                        <br/>
                        <p>Sign In to your account</p>
                    </div>

                    <form class="mt-4">
                        <div class="mb-3">
                            <input [(ngModel)]="loginUser.username" name="loginUser.username" type="email" 
                                    class="form-control form-control-lg" 
                                    name="email" id="signinSrEmail" tabindex="1" 
                                    placeholder="email@address.com"
                                    aria-label="email@address.com" required>
                        </div>

                        <div class="mb-3">
                            <input [(ngModel)]="loginUser.password" name="loginUser.password" type="password" class="js-toggle-password form-control form-control-lg" name="password" id="signupSrPassword" placeholder="8+ characters required" aria-label="8+ characters required" required
                            minlength="8" data-hs-toggle-password-options='{
                            "target": "#changePassTarget",
                            "defaultClass": "bi-eye-slash",
                            "showClass": "bi-eye",
                            "classChangeTarget": "#changePassIcon"
                        }'>
                        </div>

                        <div class="form-check">
                            <input id="_dm-loginCheck" class="form-check-input" type="checkbox">
                            <label for="_dm-loginCheck" class="form-check-label">
                                Remember me
                            </label>
                        </div>

                        <div class="d-grid mt-5">
                            <!--<button class="btn btn-primary btn-lg" type="submit">Sign In</button>-->
                            <button (click)="signIn()" type="submit" class="btn btn-primary btn-lg">Sign in</button>
                        </div>
                    </form>

                    <div class="d-flex justify-content-between mt-4">
                        <a href="#" class="btn-link text-decoration-none">Forgot password ?</a>
                        <!-- <a href="#" class="btn-link text-decoration-none">Create a new account</a> -->
                    </div>
                
                </div>
            </div>            
        </div>  
    </div>
    <footer>
        <div class="content__boxed">
            <div class="content__wrap py-3 py-md-1 d-flex flex-column flex-md-row align-items-md-center">
                <div class="text-nowrap mb-4 mb-md-0">Copyright &copy; 2023 <a href="#" class="ms-1 btn-link fw-bold">Phylio, LLC</a></div>
                <!-- <nav class="nav flex-column gap-1 flex-md-row gap-md-3 ms-md-auto" style="row-gap: 0 !important;">
                    <a class="nav-link px-0" href="#">Policy Privacy</a>
                    <a class="nav-link px-0" href="#">Terms and conditions</a>
                    <a class="nav-link px-0" href="#">Contact Us</a>
                </nav> -->
            </div>
        </div>
    </footer>
</div>
