import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginUser } from 'src/app/login/login-user';
import { AppSettings } from 'src/app/shared/app.settings';
import { Merchant } from 'src/app/shared/merchant';
import { HttpService } from '../http/http-service';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  constructor(private httpService: HttpService, private appSettings: AppSettings) {}

  public newMerchant<T>(merchant: Merchant) : Observable<T> {
    this.httpService.endpoint = this.appSettings.identityApiUrl + "merchants";
    return this.httpService.post<T>(merchant);
  }

  public signIn<T>(login: LoginUser) : Observable<T> {
    this.httpService.endpoint = environment.identityApiUrl + "/auth";
    return this.httpService.post<T>(login);
  }
}
