<div class=" content__boxed overlapping">
    <div class="content__wrap">
        <!-- Breadcrumb -->
        <!-- {% comment %} <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><a href="./index.html">Home</a></li>
                <li class="breadcrumb-item"><a href="./app-views.html">App Views</a></li>
                <li class="breadcrumb-item active" aria-current="page">Calendar</li>
            </ol>
        </nav> {% endcomment %} -->
        <!-- END : Breadcrumb -->
        <h1 class="page-title mb-0 mt-2">Profile</h1>
        <p class="lead">
         Tell us about yourself.
        </p>
    </div>
</div>

<!-- <div class="content__header content__boxed"> -->
<div class="content__boxed">
    <div class="content__wrap d-md-flex align-items-start">
        <figure class="m-0">
            <div class="d-inline-flex align-items-center position-relative pt-xl-5 mb-3">
                <div class="flex-shrink-0">
                    <img class="img-xl rounded-circle"
                         src="/assets/img/profile-photos/5.png"
                         alt="Profile Picture"
                         loading="lazy">
                </div>
                <div class="flex-grow-1 ms-4">
                    <a href="#" class="h3 btn-link">Danny Hughes</a>
                    <p class="text-muted m-0">
                    </p>
                    <!-- Social network button -->
                    <div class="mt-3 text-reset">
                        <a href="#" class="btn btn-icon btn-hover bg-blue-700 text-white">
                            <i class="demo-psi-facebook fs-4"></i>
                        </a>
                        <a href="#" class="btn btn-icon btn-hover bg-blue-400 text-white">
                            <i class="demo-psi-twitter fs-4"></i>
                        </a>
                        <a href="#" class="btn btn-icon btn-hover bg-red text-white">
                            <i class="demo-psi-google-plus fs-4"></i>
                        </a>
                        <a href="#" class="btn btn-icon btn-hover bg-orange text-white">
                            <i class="demo-psi-instagram fs-4"></i>
                        </a>
                    </div>
                    <!-- END : Social network button -->
                </div>
            </div>
            <blockquote class="blockquote">
                <p>
                    How about if I sleep a little bit longer and forget all this nonsense.
                </p>
            </blockquote>
            <!-- <figcaption class="blockquote-footer mb-xl-0">
                Someone famous - <cite title="Source Title">Stephen Tran</cite>
            </figcaption> -->
        </figure>
        <!-- <div class="d-inline-flex justify-content-end pt-xl-5 gap-2 ms-auto">
            <button class="btn btn-light text-nowrap">Edit Profile</button>
            <button class="btn btn-success text-nowrap">Send Message</button>
        </div>  -->
    </div>
</div>
<div class="content__boxed">
    <div class="content__wrap">
        <div class="d-md-flex gap-4">
            <!-- Sidebar -->
            <div class="w-md-200px flex-shrink-0">
                <h5> </h5>
                <ul class="list-unstyled mb-3">
                    <li class="mb-2">
                        <i class="demo-psi-map-marker-2 fs-5 me-3"></i> San Jose CA
                </li>
                <!-- <li class="mb-2">
                    <i class="demo-psi-internet fs-5 me-3"></i><a href="http://themeon.net/" class="btn-link text-decoration-underline" target="blank">http://themeon.net/</a>
                </li>  -->
                <li class="mb-2">
                    <i class="demo-psi-old-telephone fs-5 me-3"></i>(+1) 234 456 1234
            </li>
        </ul>
        <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis perspiciatis debitis aut nobis minima quia quasi aliquid minus consequatur dolore.
        </p>
        <h5 class="mt-5">Skills</h5>
        <div class="d-flex flex-wrap gap-2">
            <a href="#" class="btn btn-xs btn-outline-light text-nowrap">PHP Programming</a>
            <a href="#" class="btn btn-xs btn-outline-light text-nowrap">Marketing</a>
            <a href="#" class="btn btn-xs btn-outline-light text-nowrap">Graphic Desig</a>
            <a href="#" class="btn btn-xs btn-outline-light text-nowrap">Sketch</a>
            <a href="#" class="btn btn-xs btn-outline-light text-nowrap">Photography</a>
            <a href="#" class="btn btn-xs btn-outline-light text-nowrap">HTML</a>
            <a href="#" class="btn btn-xs btn-outline-light text-nowrap">CSS</a>
        </div>
       <!-- <h5 class="mt-5">Gallery</h5>
        <div class="row g-1 mb-3">
            <div class="col-4">
                <img class="img-fluid rounded" src="/static/assets/img/megamenu/img-3.jpg" alt="thumbs" loading="lazy">
            </div>
            <div class="col-4">
                <img class="img-fluid rounded" src="/static/assets/img/megamenu/img-6.jpg" alt="thumbs" loading="lazy">
            </div>
            <div class="col-4">
                <img class="img-fluid rounded" src="/static/assets/img/megamenu/img-4.jpg" alt="thumbs" loading="lazy">
            </div>
            <div class="col-4">
                <img class="img-fluid rounded" src="/static/assets/img/megamenu/img-2.jpg" alt="thumbs" loading="lazy">
            </div>
            <div class="col-4">
                <img class="img-fluid rounded" src="/static/assets/img/megamenu/img-5.jpg" alt="thumbs" loading="lazy">
            </div>
            <div class="col-4">
                <img class="img-fluid rounded" src="/static/assets/img/megamenu/img-1.jpg" alt="thumbs" loading="lazy">
            </div>
        </div>  -->
    </div>
    <!-- END : Sidebar -->
    <div class="vr d-none"></div>
    <!-- Content -->
    <div class="flex-fill">
        <div class="card mb-3">
            <!-- Card with header -->
            <div class="card h-100">
                <div class="card-header toolbar">
                    <div class="toolbar-start">
                        <h5 class="m-0">About Me</h5>
                    </div>
                    <div class="toolbar-end">
                        <a href="#" class="btn btn-icon btn-hover bg-blue-700">
                            <i class="demo-pli-pen-5 fs-5 me-2"></i>
                        </a>
                    </div>
                </div>

                <div class="card-body">
                    <p class="card-text" >
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullam laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum.
                        Sedperspiciatis unde omniste natus error sit voluptatem laudantium totam rem aperiam eaque ipsa quae.
                    </p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <!-- Card with header -->
            <div class="card h-100">
                <div class="card-header toolbar">
                    <div class="toolbar-start">
                        <h5 class="m-0">Biography</h5>
                    </div>
                    <div class="toolbar-end">
                        <a href="#" class="btn btn-icon btn-hover bg-blue-700">
                            <i class="demo-pli-pen-5 fs-5 me-2"></i>
                        </a>
                    </div>
                </div>
                <div class="card-body">
                    <p class="card-text">
                        Vinteger eu libero rutrum, imperdiet arcueniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehender it in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id laborum.
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
                        accusantium doloremque laudantium.totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt.
                    </p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <!-- Card with header -->
            <div class="card h-100">
                <div class="card-header toolbar">
                    <div class="toolbar-start">
                        <h5 class="m-0">Experience</h5>
                    </div>
                    <div class="toolbar-end">
                        <a href="#" class="btn btn-icon btn-hover bg-blue-700">
                            <i class="demo-pli-pen-5 fs-5 me-2"></i>
                        </a>
                    </div>
                </div>
                <div class="card-body">
                    <p class="card-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, odit?
                    </p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <!-- Card with header -->
            <div class="card h-100">
                <div class="card-header toolbar">
                    <div class="toolbar-start">
                        <h5 class="m-0">Expertise Areas</h5>
                    </div>
                    <div class="toolbar-end">
                         <!-- <a href="#" class="btn btn-icon btn-hover bg-blue-700">
                            <i class="demo-pli-pen-5 fs-5 me-2"></i>
                        </a> -->
                    </div>
                </div>
                <div class="card-body">
                     <!-- <p class="card-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, odit?
                    </p> -->
                    <!-- Ordered liat -->
                   <!-- <ul class="list-unstyled">
                        <li class="d-flex align-items-center gap-2 mb-2"><span class="d-inline-block bg-info rounded-circle p-1"></span> Lorem ipsum dolor sit amet</li>
                        <li class="d-flex align-items-center gap-2 mb-2"><span class="d-inline-block bg-info rounded-circle p-1"></span> Consectetur adipiscing elit</li>
                        <li class="d-flex align-items-center gap-2 mb-2"><span class="d-inline-block bg-info rounded-circle p-1"></span> Integer molestie lorem at massa</li>
                        <li class="d-flex align-items-center gap-2 mb-2"><span class="d-inline-block bg-info rounded-circle p-1"></span> Facilisis in pretium nisl aliquet</li>
                        <li class="d-flex align-items-center gap-2 mb-2"><span class="d-inline-block bg-info rounded-circle p-1"></span> Nulla volutpat aliquam velit</li>
                    </ul> -->
                    <!-- END : Ordered liat -->
                    <!-- Add placeholder -->
                    <p>
                        A tag is a keyword or term assigned to assist in customers finding you.
                    </p>
                    <input type="text"
                           name="tags"
                           class="form-control tagin"
                           value="Chef"
                           data-placeholder="Add a tag... (then press comma)" />
                    <!-- END : Add placeholder -->
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <!-- Card with header -->
            <div class="card h-100">
                <div class="card-header toolbar">
                    <div class="toolbar-start">
                        <h5 class="m-0">My Certificates</h5>
                    </div>
                    <div class="toolbar-end">
                        <a href="#" class="btn btn-icon btn-hover bg-blue-700">
                            <i class="demo-pli-pen-5 fs-5 me-2"></i>
                        </a>
                    </div>
                </div>
                <div class="card-body">
                    <p class="card-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, odit?
                    </p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header toolbar">
                <div class="toolbar-start">
                    <h5 class="m-0">Gallery</h5>
                </div>
                <div class="toolbar-end">
                    <a href="#" class="btn btn-icon btn-hover bg-blue-700">
                        <i class="demo-pli-pen-5 fs-5 me-2"></i>
                    </a>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex mb-4">
                    <!-- <div class="flex-shrink-0">
                        <img class="img-sm rounded-circle" src="/static/assets/img/profile-photos/10.png" alt="Profile Picture" loading="lazy">
                    </div>  -->
                    <div class="flex-grow-1 ms-3">
                        <!-- <div class="mb-1">
                            <a href="#" class="h6 btn-link">Lisa D</a> added new photos {% endcomment %}
                        </div> -->
                       <small class="d-block text-muted mb-2"><i class="demo-pli-laptop fs-5"></i> - From Notebook - 34 min ago</small>
                    <p>
                        Lorem Ipsum decided to leave for the far World of Grammar.
                        </p> 
                        <div class="row g-1">
                            <div class="col-6">
                                <img class="img-fluid rounded"
                                     src="/assets/img/sample-img/img-3.jpg"
                                     alt="nature image"
                                     loading="lazy" />
                            </div>
                            <div class="col-6">
                                <div class="row g-1 mb-3">
                                    <div class="col-6">
                                        <img class="img-fluid rounded"
                                             src="/assets/img/sample-img/img-5.jpg"
                                             alt="cooking"
                                             loading="lazy">
                                    </div>
                                    <div class="col-6">
                                        <img class="img-fluid rounded"
                                             src="/assets/img/sample-img/img-1.jpg"
                                             alt="my car"
                                             loading="lazy">
                                    </div>
                                    <div class="col-6">
                                        <img class="img-fluid rounded"
                                             src="/assets/img/sample-img/img-2.jpg"
                                             alt="coffee"
                                             loading="lazy"/>
                                    </div>
                                    <div class="col-6">
                                        <img class="img-fluid rounded"
                                             src="/assets/img/sample-img/img-4.jpg"
                                             alt="town"
                                             loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END : Newsfeed / Comment Content  -->
                <!-- Newsfeed / Comment Content -->
                <div class="d-flex mb-4">
                    <div class="flex-grow-1 ms-3">
                        <div class="mb-1">
                        </div>
                    </div>
                </div>
                <!-- END : Newsfeed / Comment Content  -->
            </div>
        </div>
    </div>
    <!-- END : Content -->
     </div>
</div>
</div>