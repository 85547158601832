<div class=" content__boxed overlapping">
    <div class="content__wrap">

        <h1 class="page-title mb-0 mt-2">File Uploads</h1>

        <p class="lead">
            Transmitting data from one computer system to another through means of a network.
        </p>
    </div>
</div>


<div class="content__wrap">
    <div class="card">
        <div class="card-body">

            <!--Dropzonejs using Bootstrap theme-->

            <h5>Custom style</h5>
            <p class="mb-5">This is a bootstrap theme of Dropzone.js with a completely different user experience.</p>

            <!-- Buttons container -->
            <div class="hstack gap-3">

                <!-- The fileinput-button span is used to style the file input field as button -->
                <button class="btn btn-primary hstack gap-2 _dm-fileInputButton">
                    <i class="demo-psi-add fs-3"></i>
                    <span class="vr"></span>
                    Add files
                </button>

                <button id="dz-upload-btn" class="btn btn-success ms-auto" type="submit" disabled>
                    Upload
                </button>

                <button id="dz-remove-btn" class="btn btn-light" type="reset" disabled>
                    Remove all
                </button>

            </div>
            <!-- END : Buttons container -->

            <!-- File previews -->
            <div id="_dm-dzPreviews" class="mt-4">
                <div id="_dm-dzTemplate" class="border-top">
                    <div class="d-flex align-items-center py-3">

                        <!-- Image preview -->
                        <div class="flex-shrink-0">
                            <img class="dz-img rounded" data-dz-thumbnail>
                        </div>

                        <div class="d-flex justify-content-between align-items-center flex-grow-1 ms-3">

                            <!-- File informations -->
                            <div class="">
                                <p class="h5 mb-0" data-dz-name></p>
                                <small data-dz-size></small>
                                <small class="dz-error text-danger" data-dz-errormessage></small>
                            </div>
                            <!-- END : File informations -->

                            <!-- Progress and remove button -->
                            <div class="flex-shrink-0 d-flex align-items-center flex-row gap-3">
                                <div id="_dm-dzProgress" style="width: 100px; opacity:0">
                                    <div class="progress active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
                                        <div class="progress-bar bg-success" style="width:0%;" data-dz-uploadprogress></div>
                                    </div>
                                </div>

                                <button data-dz-remove class="btn btn-icon btn-xs btn-danger rounded-circle dz-cancel">
                                    <i class="demo-psi-trash"></i>
                                </button>
                            </div>
                            <!-- END : Progress and remove button -->

                        </div>
                    </div>

                </div>
            </div>
            <!-- END : File previews -->

            <!-- END : Dropzonejs using Bootstrap theme -->

        </div>
    </div>
</div>
