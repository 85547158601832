import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterModule, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggedInUser } from '../login/loggedInUser';
import { AppSettings } from '../shared/app.settings';

@Injectable({
  providedIn: 'root'
})
export class SessionRouteGuard implements CanActivate, CanDeactivate<unknown> {

  constructor(private router: Router, private appSettings: AppSettings) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      var user = this.getLoggedInUser();
      if (user?.hasToken === true) {
        this.router.navigate(['portal', { outlets: { aside: 'dashboard' }, }]);
      }
      return true;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  public getLoggedInUser(): LoggedInUser {
    var json = sessionStorage.getItem(this.appSettings.sessionStorageKey);
    var userFromStorage: LoggedInUser = JSON.parse(json!);
    return userFromStorage;
  }
}
