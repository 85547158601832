 <!-- CONTENTS -->
        <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
            <div class="content__boxed overlapping">
                <div class="content__wrap">

                    <!-- Breadcrumb -->
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item"><a href="./index.html">Home</a></li>
                            <li class="breadcrumb-item"><a href="./app-views.html">App Views</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Calendar</li>
                        </ol>
                    </nav> -->
                    <!-- END : Breadcrumb -->

                    <h1 class="page-title mb-0 mt-2">Calendar</h1>

                    <p class="lead">
                        A full-sized drag &amp; drop JavaScript event calendar.
                    </p>

                </div>

            </div>

            <div class="content__boxed">
                <div class="content__wrap">
                    <div class="card">
                        <div class="card-body">

                            <div class="d-md-flex gap-4">

                                <!-- Calendar sidebar -->
                                <div class="w-md-160px w-xl-200px flex-shrink-0 mb-3">
                                    <div class="d-grid">
                                        <button class="btn btn-primary" type="button">Add new event</button>
                                    </div>

                                    <!-- Calendar - Checkboxes -->
                                    <h5 class="mt-5 mb-3">My Calendar</h5>
                                    <div class="form-check mb-3">
                                        <input id="_dm-checkbox1" class="form-check-input" type="checkbox" value="">
                                        <label for="_dm-checkbox1" class="form-check-label">
                                            Personal Calendar
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input id="_dm-checkbox2" class="form-check-input" type="checkbox" value="" checked>
                                        <label for="_dm-checkbox2" class="form-check-label">
                                            Reminders
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input id="_dm-checkbox3" class="form-check-input" type="checkbox" value="" checked>
                                        <label for="_dm-checkbox3" class="form-check-label">
                                            Task
                                        </label>
                                    </div>
                                    <!-- END : Calendar - Checkboxes -->

                                    <!-- Calendar - Upcoming event -->
                                    <h5 class="mt-5 mb-3">Upcoming event</h5>
                                    <div class="list-group list-group-borderless">

                                        <!-- List item -->
                                        <a class="bg-info list-group-item list-group-item-action mb-2" href="#">
                                            <h6 class="mb-2 text-white">Betty Murphy's Birthday</h6>
                                            <div class="d-flex justify-content-between text-white">
                                                <small>09:30 - 11:59</small>
                                                <small>Mar 12</small>
                                            </div>
                                        </a>

                                        <!-- List item -->
                                        <a class="bg-warning list-group-item list-group-item-action mb-2" href="#">
                                            <h6 class="mb-2 text-white">Company Meeting</h6>
                                            <div class="d-flex justify-content-between text-white-50">
                                                <small>02:00 - 03:30</small>
                                                <small>Mar 07</small>
                                            </div>
                                        </a>

                                        <!-- List item -->
                                        <a class="bg-danger list-group-item list-group-item-action mb-2" href="#">
                                            <h6 class="mb-2 text-white">Presentation</h6>
                                            <div class="d-flex justify-content-between text-white-50">
                                                <small>09:55 - 10:55</small>
                                                <small>Mar 05</small>
                                            </div>
                                        </a>

                                    </div>
                                    <!-- END : Calendar - Upcoming event -->

                                </div>
                                <!-- END : Calendar sidebar -->

                                <!-- Full calendar container -->
                                <div class="flex-fill">
                                    <div id="_dm-calendar"></div>
                                </div>
                                <!-- END : Full calendar container -->

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
        <!-- END - CONTENTS -->
